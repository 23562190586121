import styled from "styled-components";

const MessageArrowIndicator = styled.div`
  width: 100%;

  color: rgb(168, 168, 168);
  background-color: rgb(61, 61, 61);
  margin-top: 15px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 25px 23px;
  position: relative;
  font-size: 1em;
`;
export default MessageArrowIndicator;
