import { FirebaseApp, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfigProd = {
  apiKey: "AIzaSyCpTP-_eZjRthBwNPjxZE7o8m07w4qD6tg",
  authDomain: "musicplayce-prod.firebaseapp.com",
  projectId: "musicplayce-prod",
  storageBucket: "musicplayce-prod.appspot.com",
  messagingSenderId: "581502747976",
  appId: "1:581502747976:web:021ee65b04010af65b1f7d",
  measurementId: "G-MT5PK9X7C0",
};

const firebaseConfigHml = {
  apiKey: "AIzaSyBHDuqX4Tb_H4SurfeGD6aBTtCE1N0XaSM",
  authDomain: "musicplayce-hml.firebaseapp.com",
  projectId: "musicplayce-hml",
  storageBucket: "musicplayce-hml.appspot.com",
  messagingSenderId: "974934384667",
  appId: "1:974934384667:web:690f76a777f468137658be",
  measurementId: "G-NLNEJCL9PE",
};

const firebaseConfigDev = {
  apiKey: "AIzaSyDhGt62kxajvSG6ObL8zsu-zZcPn4-r20w",
  authDomain: "musicplayce-dev-65d73.firebaseapp.com",
  projectId: "musicplayce-dev-65d73",
  storageBucket: "musicplayce-dev-65d73.appspot.com",
  messagingSenderId: "230703583679",
  appId: "1:230703583679:web:c0908b01613bfd12be910a",
  measurementId: "G-P2K2KPYS7H",
};

let app: FirebaseApp;

app = initializeApp(firebaseConfigProd);

// Initialize Firebase
const db = getFirestore(app);
export default db;
