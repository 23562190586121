import styled from "styled-components";

const MoreInfo = styled.a`
  color: #0d65ff;
  text-decoration: none;

  padding: 28px 31px 0px;
  font-size: 0.8em;
  letter-spacing: 0.1em;
`;
export default MoreInfo;
