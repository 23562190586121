import styled from "styled-components";

const ArtistName = styled.p`
  margin: 0;
  width: 40vw;
  padding: 10px 0px 10px 31px;
  box-sizing: border-box;

  background: #0d65ff;

  color: #fff;

  font-size: 0.8em;
  line-height: 1em;
  text-transform: uppercase;
`;
export default ArtistName;
