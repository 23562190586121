import styled from "styled-components";

const FooterSection = styled.div`
  background: #262626;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 30px;
`;
export default FooterSection;
