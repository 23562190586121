import styled from "styled-components";

const AvatarPicture = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin: 0 auto;
  background-image: url(${(props) => props.theme});
  background-size: contain;
  overflow: visible;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: -17px;
    left: 30%;
    width: 0;
    border-bottom: 10px solid rgb(61, 61, 61);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-radius: 3px;
  }
`;
export default AvatarPicture;
