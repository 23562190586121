import styled from "styled-components";

const TitleInactivePage = styled.h1`
  font-weight: 700;

  color: #fff;

  font-size: 1.5em;
  line-height: 1em;
  max-width: 80%;
  padding: 20px 31px 10px;
  text-align: center;
`;
export default TitleInactivePage;
