import styled from "styled-components";

const IntroAuditionSection = styled.section`
  display: flex;
  margin: 0 auto;
  max-width: 1000px;
  width: 100vw;
  height: 100vh;
  padding: 52px 0px;
  flex-direction: column;
  justify-content: flex-end;

  background-image: linear-gradient(
      180deg,
      rgba(32, 32, 32, 0) 31.03%,
      rgba(32, 32, 32, 0.6) 57.99%,
      rgba(32, 32, 32, 0.85) 75.73%,
      rgba(32, 32, 32, 0.970257) 89.88%,
      #202020 98.31%
    ),
    url(${(props) => props.theme});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
export default IntroAuditionSection;
