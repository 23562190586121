import React, { useEffect, useState } from "react";

import { doc, getDoc, setDoc, increment } from "@firebase/firestore";
import db from "./core/database/firebase";
import { Helmet } from "react-helmet";

import rightDownIcon from "./assets/right-down.png";
import logoMusicPlayce from "./assets/logo-musicplayce.png";

// import the styled component:
import IntroAuditionSection from "./core/components/InfoArtist/IntroAuditionSection";
import ArtistName from "./core/components/InfoArtist/ArtistName";
import TitleAudition from "./core/components/InfoArtist/TitleAudition";
import SubtitleAudition from "./core/components/InfoArtist/SubtitleAudition";
import MoreInfo from "./core/components/InfoArtist/MoreInfo/MoreInfo";
import MoreInfoIcon from "./core/components/InfoArtist/MoreInfo/MoreInfoIcon";
import InfoAuditionSection from "./core/components/InfoAudition/InfoAuditionSection";
import AvatarPicture from "./core/components/InfoAudition/AvatarPicture";
import Message from "./core/components/InfoAudition/Message";
import MessageArrowIndicator from "./core/components/InfoAudition/MessageArrowIndicator";
import ButtonDynamicLink from "./core/components/InfoAudition/ButtonDynamicLink";
import FooterSection from "./core/components/Footer/FooterSection";
import LogoMusicPlayce from "./core/components/Footer/LogoMusicPlayce";
import LoaderSection from "./core/components/Loader/LoaderSection";
import { LoaderIndicator } from "./core/components/Loader/LoaderIndicator";
import InactivePageSection from "./core/components/InactivePage/InactivePageSection";
import TitleInactivePage from "./core/components/InactivePage/TitleInactivePage";


type ArtistModel = {
  active: boolean;
  name: string;
  title: string;
  subtitle: string;
  avatarUrl: string;
  dynamicLink: string;
};

function App() {
  const artistId = window.location.pathname.split("/")[1];

  // Estado para armazenar dados da LandingPage
  const [artist, setArtist] = useState<ArtistModel>({
    active: false,
    name: "",
    title: "",
    subtitle: "",
    avatarUrl: "",
    dynamicLink: "",
  });

  const [loading, setLoading] = useState(true);
  const [errorScreen, setErrorScreen] = useState(false);



  // Incrementar quantidade de acessos no dynamic link
  const clickDynamicLinkButton = () => {

    if (localStorage.getItem(artistId) === artistId) {
      window.open(artist.dynamicLink, "_blank");
    } else {
      setDoc(
        doc(db, "landingPages", artistId),
        {
          clicksCount: increment(1),
        },
        { merge: true }
      );
      localStorage.setItem(artistId, artistId);
      window.open(artist.dynamicLink, "_blank");
    }
  };

  // Requisiçao da landing page
  useEffect(() => {
    if (!artistId) {
      setLoading(false);
      setErrorScreen(true);
      return;
    } else {
      getDoc(doc(db, "landingPages", artistId)).then((doc) => {
        if (doc.exists()) {
          setArtist({
            active: doc.data()!.active,
            name: doc.data()!.mainArtist.name,
            title: doc.data()!.title,
            subtitle: doc.data()!.subtitle,
            avatarUrl: doc.data()!.mainArtist.avatarUrl,
            dynamicLink: doc.data()!.dynamicLink,
          });
          setLoading(false);
          
        } else {
          setLoading(false);
          setErrorScreen(true);
        }
      });

    }
    if (localStorage.getItem('viewedArtist ' + artistId) === "view") {
      return;
    } else {
      localStorage.setItem('viewedArtist ' + artistId, 'view');
      console.log('------------------');

      getDoc(doc(db, "landingPages", artistId)).then((document) => {
        if (document.exists()) {
          setDoc(
            doc(db, "landingPages", artistId),
            {
              viewsCount: increment(1),
            },
            { merge: true }
          );
        } else {
          setLoading(false);
          setErrorScreen(true);
        }
      });

    }
  }, [artistId]);

  function checkDevice() { 
    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
      clickDynamicLinkButton();
      // está utilizando celular
     }
    else {
      window.open("https://musicplayce.com/", "_blank");
      clickDynamicLinkButton();
      
      // não é celular
     }
   }


  if (loading) {
    return (
      <LoaderSection>
        <LoaderIndicator />
      </LoaderSection>
    );
  }

  if (errorScreen) {
    return (
      <InactivePageSection>
        <LogoMusicPlayce src={logoMusicPlayce} />

        <TitleInactivePage>Esta página não foi encontrada.</TitleInactivePage>
      </InactivePageSection>
    );
  }
  if (artist && !artist.active) {
    return (
      <InactivePageSection>
        <LogoMusicPlayce src={logoMusicPlayce} />

        <TitleInactivePage>
          Esta página está inativa no momento.
        </TitleInactivePage>
      </InactivePageSection>
    );
  }

  return (
    <div className="container">
      <Helmet>
        <title>MusicPlayce - {artist.name}</title>
        {/* <!--Twitter Cards--> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="%PUBLIC_URL%" />
        <meta name="twitter:title" content={"MusicPlayce - " + artist.name} />
        <meta
          name="twitter:description"
          content={"MusicPlayce - Tenha sua música gravada por " + artist.name}
        />
        <meta name="twitter:image" content={artist.avatarUrl} />
        {/* <!--Fim Twitter Cards--> */}

        {/* <!--Facebook Open Graph--> */}
        <meta property="og:type" content="web" />
        <meta property="og:title" content={"MusicPlayce - " + artist.name} />
        <meta
          property="og:description"
          content={"MusicPlayce - Tenha sua música gravada por " + artist.name}
        />
        <meta property="og:image" content={artist.avatarUrl} />
        <meta property="og:url" content="%PUBLIC_URL%" />
        {/* <!--Fim do Facebook Open Graph--> */}
        <link rel="apple-touch-icon" href={artist.avatarUrl} />
        <meta
          name="description"
          content={
            "MusicPlayce - Tenha sua composição gravada pelo " + artist.name
          }
        />
        <title>{"MusicPlayce - " + artist.name}</title>
      </Helmet>
      {/* Informações do Artista */}


      <IntroAuditionSection theme={artist.avatarUrl}>
        <ArtistName>{artist.name}</ArtistName>

        <TitleAudition>{artist.title}</TitleAudition>
        <SubtitleAudition>{artist.subtitle}</SubtitleAudition>
        <MoreInfo href="#info">
          SAIBA MAIS
          <MoreInfoIcon src={rightDownIcon} />
        </MoreInfo>
      </IntroAuditionSection>
      {/* FIM Informações do Artista */}

      {/* Informações do Audição */}
      <InfoAuditionSection id="info">
        <AvatarPicture theme={artist.avatarUrl} />

        <MessageArrowIndicator>
          Tem projeto novo na área e estamos a procura de música boa para entrar
          nesse novo repertório
        </MessageArrowIndicator>
        <Message>
          Por isso, abrimos uma audição digital na plataforma MusicPlayce,
          graças a ela, agora será possível ouvir todas as guias que nos
          enviarem
        </Message>
        <Message>Curtiu? Então bora lá!</Message>
        <Message>
          O MusicPlayce é a mais nova ferramenta de trabalho do compositor, e
          para participar da nossa audição digital é bem fácil
        </Message>
        <Message>1. Instale o App MusicPlayce</Message>
        <Message>2. Crie sua conta e faça login</Message>
        <Message>3. Depois, faça o upload das guias</Message>
        <Message>4. Participe da nossa audição digital</Message>

        <ButtonDynamicLink onClick={checkDevice}>
          PARTICIPAR AGORA
        </ButtonDynamicLink>
      </InfoAuditionSection>
      {/* FIM Informações do Audição */}
      <FooterSection>
        <LogoMusicPlayce src={logoMusicPlayce} />
      </FooterSection>

      
    </div>
  );
}

export default App;
