import styled from "styled-components";

const TitleAudition = styled.h1`
  font-weight: 700;

  color: #fff;

  font-size: 2.7em;
  line-height: 1em;
  max-width: 80%;
  padding: 20px 31px 10px;
`;
export default TitleAudition;
