import styled from "styled-components";

const ButtonDynamicLink = styled.button`
  margin: 60px auto;
  padding: 18px 40px;
  border-radius: 50px;
  max-width: 250px;
  text-decoration: none;
  border: none;

  color: #fff;
  background-color: #0d65ff;
`;
export default ButtonDynamicLink;
