import styled from "styled-components";

const InfoAuditionSection = styled.section`
  display: flex;
  margin: 0 auto;
  max-width: 1000px;
  width: 100vw;
  padding: 0px 30px;
  flex-direction: column;
`;
export default InfoAuditionSection;
