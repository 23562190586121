import styled from "styled-components";

const SubtitleAudition = styled.h4`
  font-weight: 600;

  color: rgb(124, 124, 124);

  font-size: 1.3em;
  line-height: 1em;
  padding: 0px 31px;
`;
export default SubtitleAudition;
